/**
 * @license
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export function contains(obj: T, key: keyof T): boolean {
 return Object.prototype.hasOwnProperty.call(obj, key);
}

export function safeGet(
 obj: T,
 key: K
): T[K] | undefined {
 if (Object.prototype.hasOwnProperty.call(obj, key)) {
 return obj[key];
 } else {
 return undefined;
 }
}

export function isEmpty(obj: object): obj is {} {
 for (const key in obj) {
 if (Object.prototype.hasOwnProperty.call(obj, key)) {
 return false;
 }
 }
 return true;
}

export function map(
 obj: { [key in K]: V },
 fn: (value: V, key: K, obj: { [key in K]: V }) => U,
 contextObj?: unknown
): { [key in K]: U } {
 const res: Partial<{ [key in K]: U }> = {};
 for (const key in obj) {
 if (Object.prototype.hasOwnProperty.call(obj, key)) {
 res[key] = fn.call(contextObj, obj[key], key, obj);
 }
 }
 return res as { [key in K]: U };
}
